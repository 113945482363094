import { CompanyAuthService } from "@/api/instance"

export const ResetPassword = async (password, token) => {
  await CompanyAuthService.post(
    "/reset-password",
    { password },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
}

<template>
  <v-form @submit.prevent>
    <p class="input-label">New password <span>*</span></p>
    <v-text-field
      name="password"
      v-model="newPassword"
      prepend-inner-icon="lock"
      type="password"
      outlined
    ></v-text-field>
    <p class="input-label">Confirm new password <span>*</span></p>
    <v-text-field
      name="confirm_password"
      v-model="confirmNewPassword"
      :rules="confirmPasswordRule"
      prepend-inner-icon="lock"
      type="password"
      outlined
    ></v-text-field>
    <v-btn class="submit-btn" color="primary" type="button" @click="submit"
      >Submit</v-btn
    >
  </v-form>
</template>

<script>
import { ResetPassword } from "@/api/auth/reset-password"
export default {
  data() {
    return {
      confirmPasswordRule: [
        v => v === this.newPassword || "Both Passwords must match"
      ],
      newPassword: "",
      confirmNewPassword: ""
    }
  },
  methods: {
    async submit() {
      if (this.newPassword === this.confirmNewPassword) {
        await ResetPassword(this.newPassword, this.$route.query.token)
        this.$router.push("/auth/login")
      }
    }
  }
}
</script>
<style scoped>
.submit-btn {
  float: right;
  font-size: 20px;
  letter-spacing: normal;
}
.input-label {
  font-size: 22px;
  color: var(--v-grey-base);
}
.input-label span {
  vertical-align: super;
  color: #ff0000;
}
</style>
